import React from "react";
import { useTranslations } from "gatsby-plugin-translate";

const Footer = () => {
  const t = useTranslations();

  return (
    <div className="footer">
      <div>
        <span>{t`Todas as esculturas e respetivas fotografias são da autoria e pertencem a Isabel de Andrade.`}</span>
        <br />
        <span>{t`A prévia autorização do seu uso deve ser solicitada através do formulário de contacto.`}</span>
        <br />
        <br />© 2023
      </div>
    </div>
  );
};

export default Footer;
